var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5",attrs:{"id":"facility-tab-fanaty","flat":""}},[_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.t('groups.fanaty_service_fee_percent'),"dense":"","outlined":"","prefix":"%","readonly":_vm.option===2,"rules":[_vm.required, _vm.between(_vm.dataComputed.fanaty_service_fee_percent, 0, 100), _vm.regexValidator(_vm.dataComputed.fanaty_service_fee_percent, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format')]},on:{"input":function (e) {
              if (!_vm.charUnique(e, '.') || _vm.regexValidator(_vm.dataComputed.fanaty_service_fee_percent, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format') !== true) {
                _vm.dataComputed.fanaty_service_fee_percent = null
              }
            },"keypress":_vm.onlyNumberDot},model:{value:(_vm.dataComputed.fanaty_service_fee_percent),callback:function ($$v) {_vm.$set(_vm.dataComputed, "fanaty_service_fee_percent", _vm._n($$v))},expression:"dataComputed.fanaty_service_fee_percent"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.t('groups.fanaty_service_fee'),"dense":"","outlined":"","prefix":"$","readonly":_vm.option===2,"rules":[_vm.required, _vm.regexValidator(_vm.dataComputed.fanaty_service_fee, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format')]},on:{"input":function (e) {
              if (!_vm.charUnique(e, '.') || _vm.regexValidator(_vm.dataComputed.fanaty_service_fee, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format') !== true) {
                _vm.dataComputed.fanaty_service_fee = null
              }
            },"keypress":_vm.onlyNumberDot},model:{value:(_vm.dataComputed.fanaty_service_fee),callback:function ($$v) {_vm.$set(_vm.dataComputed, "fanaty_service_fee", _vm._n($$v))},expression:"dataComputed.fanaty_service_fee"}})],1),_c('options-buttom-tab',{attrs:{"tab-number":_vm.tab,"is-mode-save-edit":_vm.option !== 2,"info-required":true,"no-cancel":_vm.option === 3,"no-arrow":true},on:{"onCancel":_vm.onCancelAction,"onSave":_vm.onSaveAction}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }