<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-card-text>
      <v-form ref="form">
        <v-row class="justify-center">
          <v-col
            cols="12"
            md="12"
          >
            <location-picker
              v-model="location"
              style="width: 100%; height: 400px"
              :options="locationOptions"
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-autocomplete
              v-model="dataComputed.country_code"
              :label="t('users.country')"
              :items="countries"
              item-text="name"
              item-value="iso2"
              dense
              outlined
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
              @change="onSelectCountry"
            >
              <template v-slot:item="{ item }">
                <img
                  :src="`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${item.iso2}.svg`"
                  :alt="`country-flag-${item.iso2}`"
                  width="20"
                  height="auto"
                  class="mr-2"
                >
                {{ getI18nLanguage() === 'es' ? item.translations['es']: item.name }}
                <v-chip
                  class="ml-2"
                  small
                >
                  {{ item.iso2 }}
                </v-chip>
              </template>

              <template v-slot:selection="{ item }">
                <img
                  :src="`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${item.iso2}.svg`"
                  :alt="`country-flag-${item.iso2}`"
                  width="20"
                  height="auto"
                  class="mr-2"
                >
                {{ getI18nLanguage() === 'es' ? item.translations['es']: item.name }}
                <v-chip
                  class="ml-2"
                  small
                >
                  {{ item.iso2 }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-autocomplete
              v-model="dataComputed.state_code"
              :label="t('users.state_province_region')"
              :items="filteredStates"
              item-text="name"
              item-value="state_code"
              dense
              outlined
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
              @change="onSelectState"
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="dataComputed.city_name"
              :label="t('users.city')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              id="autocomplete"
              ref="autocomplete"
              v-model="dataComputed.full_address"
              :placeholder="t('facilities.enter_location')"
              outlined
              dense
              :rules="[required]"
              @focus="value = ''"
              @input="onChangeFullAddress"
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="dataComputed.query_place_id"
              :label="t('facilities.google_place_id')"
              dense
              outlined
              :readonly="option===2"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <options-buttom-tab
      :tab-number="tab"
      :is-mode-save-edit="option !== 2"
      :info-required="false"
      :no-cancel="option === 3"
      :no-arrow="option === 2"
      :no-save="true"
      @onCancel="onCancelButton"
      @onNextTab="changeTab"
    >
    </options-buttom-tab>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { mdiMenuDown } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import { getI18nLanguage } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import { Loader } from '@googlemaps/js-api-loader'
import { LocationPicker } from 'vue2-location-picker'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    LocationPicker,
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    countries: {
      type: Array,
      default: () => [],
      required: true,
    },
    states: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()

    const canSearchLocation = ref(true)
    const location = ref({
      lat: 25.7616028,
      lng: -80.1919486,
    })
    const autocomplete = ref(null)
    const autocompletePlace = ref(null)
    const dataLocationOptions = ref({
      marker: {
        clickable: props.option !== 2,
        draggable: props.option !== 2,
      },
    })
    const locationOptions = computed(() => dataLocationOptions.value)

    const form = ref(null)
    const dataComputed = computed(() => props.dataParams)
    const filteredStates = ref([])
    const google = ref(null)
    const geocoder = ref(null)
    const autoCompleteOptions = ref({
      fields: ['name', 'geometry', 'formatted_address', 'place_id'],
      strictBounds: true,
      types: ['establishment'],
    })
    const timerPlaceId = ref(null)

    const getAutoCompleteAddress = () => {
      const place = autocompletePlace.value.getPlace()
      if (place) {
        dataComputed.value.full_address = place.formatted_address
        dataComputed.value.query_place_id = place.place_id
        dataComputed.value.latitude = place.geometry.location.lat()
        dataComputed.value.longitude = place.geometry.location.lng()
        canSearchLocation.value = false
        location.value = place.geometry.location
      }
    }

    const createInstance = async () => {
      const loader = new Loader({
        apiKey: 'AIzaSyBIMheCyv_x_ZvNkoBbFwM2uspgBdNmYz8&callback=Function.prototype',
        version: 'weekly',
        libraries: ['places'],
      })
      google.value = await loader.load()
      geocoder.value = await new google.value.maps.Geocoder()

      const inp = document.getElementById('autocomplete')
      autocompletePlace.value = await new google.value.maps.places.Autocomplete(inp, autoCompleteOptions.value)
      autocompletePlace.value.addListener('place_changed', () => {
        getAutoCompleteAddress()
      })
    }

    const searchGeocode = async () => {
      if (location.value) {
        try {
          const data = await geocoder.value.geocode({ location: location.value })
          if (data.results.length) {
            dataComputed.value.full_address = data.results[0].formatted_address
            dataComputed.value.latitude = data.results[0].geometry.location.lat
            dataComputed.value.longitude = data.results[0].geometry.location.lng
          }
        } catch (error) {
          console.error('error geocode', error)
        }
      }
    }

    const onChangeFullAddress = () => {
      clearTimeout(timerPlaceId.value)
      timerPlaceId.value = setTimeout(() => {
        if (dataComputed.value.query_place_id) dataComputed.value.query_place_id = null
      }, 1000)
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    const onCancelButton = () => {
      form.value.reset()
      emit('cancelAction')
    }

    const onSelectCountry = value => {
      filteredStates.value = []
      dataComputed.value.state_code = null
      dataComputed.value.state_name = null
      if (value) {
        filteredStates.value = props.states.filter(e => e.country_code === value)
        const country = props.countries.find(e => e.iso2 === value)
        dataComputed.value.country_name = country.name
      }
    }

    const onSelectState = value => {
      if (value) {
        const state = props.states.find(e => e.state_code === value)
        dataComputed.value.state_name = state.name
      }
    }

    watch([location], () => {
      if (canSearchLocation.value) searchGeocode()

      setTimeout(() => {
        canSearchLocation.value = true
      }, 300)
    })

    onMounted(async () => {
      await createInstance()

      if (props.option !== 1) {
        location.value = {
          lat: dataComputed.value.latitude,
          lng: dataComputed.value.longitude,
        }
      }

      setTimeout(() => {
        if (dataComputed.value.country_code) {
          filteredStates.value = props.states.filter(e => e.country_code === dataComputed.value.country_code)
        } else {
          filteredStates.value = props.states
        }
      }, 500)
    })

    return {
      location,
      autocomplete,
      autocompletePlace,
      locationOptions,
      form,

      // Computed
      dataComputed,
      filteredStates,

      // Methods
      onCancelButton,
      changeTab,
      createInstance,
      searchGeocode,
      getAutoCompleteAddress,
      onChangeFullAddress,
      onSelectCountry,
      onSelectState,

      // validations
      required,

      // i18n
      t,
      getI18nLanguage,

      icons: {
        mdiMenuDown,
      },
    }
  },
}
</script>

<style >
#pac-input {
  visibility: hidden;
  display: none;
}
</style>

<style lang="scss" scoped>
.disable-map {
  pointer-events: none;
}

.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
