<template>
  <v-card
    id="facility-tab-bank-account"
    flat
    class="mt-5"
  >
    <v-card-text>
      <v-form ref="form">
        <v-row
          justify="start"
        >
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="dataComputed.account_bank_name"
              :label="t('facilities.account_bank_name')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="dataComputed.account_number"
              :label="t('facilities.account_number')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="dataComputed.account_holder_name"
              :label="t('facilities.account_holder_name')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="dataComputed.account_routing_number"
              :label="t('facilities.account_routing_number')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            v-if="['D', 'O'].includes(connectStatus)"
            cols="12"
            md="3"
          >
            <v-btn
              color="secondary"
              block
              @click="onConnect"
            >
              {{ connectStatus === 'D' ? t('facilities.connect') : t('facilities.on_boarding_link') }}
            </v-btn>
          </v-col>

          <v-col
            v-if="dataComputed.stripe_conn_acc_url"
            cols="12"
            md="3"
          >
            <v-btn
              color="secondary"
              block
              @click="() => openNewTab(dataComputed.stripe_conn_acc_url)"
            >
              {{ t('facilities.go_to_dashboard') }}
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-chip
              :color="connectStatus === 'E' ? 'success' : 'error'"
              :class="`${connectStatus === 'E' ? 'success' : 'error'}--text mt-1`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{ connectStatus === 'E' ? t('status.active') : t('status.inactive') }}
            </v-chip>

            <v-btn
              color="secondary"
              icon
              class="ml-2"
              @click="verifyConnectStatus"
            >
              <v-icon>
                {{ icons.mdiRefresh }}
              </v-icon>
            </v-btn>
          </v-col>

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="hasPermission(152)"
            :info-required="true"
            :no-cancel="option === 3"
            :no-arrow="option === 2 || !hasPermission(152)"
            @onCancel="onCancelAction"
            @onNextTab="changeTab"
          />
        </v-row>
      </v-form>
    </v-card-text>

    <share-link
      :is-open.sync="isOpenShareLink"
      :title="t('facilities.on_boarding_link')"
      :subtitle="t('facilities.link_will_expire')"
      :label="t('fields.link')"
      :link="onBoarddingLink"
      @open-close="openUpdateShare"
    />
  </v-card>
</template>

<script>
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { required } from '@core/utils/validation'
import {
  getFacilityConnectStatus,
  putFacilityConnect,
  getFacilityConnectLink,
} from '@api'
import { mdiRefresh } from '@mdi/js'
import { openNewTab } from '@core/utils'
import usePermissions from '@core/utils/usePermissions'

import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'
import ShareLink from '../components/share-link/ShareLink.vue'

export default {
  name: 'FacilityTabBankAccount',
  components: {
    OptionsButtomTab,
    ShareLink,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { hasPermission } = usePermissions()

    const form = ref(null)
    const isOpenShareLink = ref(false)
    const connectStatus = ref(null)
    const onBoarddingLink = ref(null)

    const optionComputed = computed(() => props.option)
    const dataComputed = computed(() => props.dataParams)
    const idComputed = computed(() => props.id)
    const tabComputed = computed(() => props.tab)

    const onSaveAction = () => {
      emit('save', dataComputed.value)
    }

    const onCancelAction = () => {
      form.value.reset()
      emit('cancelAction')
    }

    const verifyConnectStatus = async () => {
      const resp = await getFacilityConnectStatus(idComputed.value)
      connectStatus.value = resp.data.connect_status
    }

    const connect = async () => {
      if (form.value.validate()) {
        const body = {
          account_bank_name: dataComputed.value.account_bank_name,
          account_holder_name: dataComputed.value.account_holder_name,
          account_number: dataComputed.value.account_number,
          account_routing_number: dataComputed.value.account_routing_number,
        }
        await putFacilityConnect(body, idComputed.value)
      }
    }
    const connectLink = async () => {
      const resp = await getFacilityConnectLink(idComputed.value)
      onBoarddingLink.value = resp.data.connect_on_boarding_link
      isOpenShareLink.value = true
    }

    const onConnect = async () => {
      if (connectStatus.value === 'D') {
        await connect()
      }

      if (connectStatus.value === 'O') {
        await connectLink()
      }

      await verifyConnectStatus()
    }

    const openUpdateShare = val => {
      isOpenShareLink.value = val
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    watch([tabComputed], async () => {
      if (tabComputed.value === 4) await verifyConnectStatus()
    })

    onMounted(async () => {
      await verifyConnectStatus()
    })

    return {
      form,
      isOpenShareLink,
      connectStatus,
      onBoarddingLink,

      // computed
      dataComputed,
      optionComputed,

      // Methods
      onSaveAction,
      onCancelAction,
      verifyConnectStatus,
      connect,
      connectLink,
      onConnect,
      openNewTab,
      openUpdateShare,
      changeTab,
      hasPermission,

      required,

      // i18n
      t,

      icons: {
        mdiRefresh,
      },
    }
  },
}
</script>

<style >
#pac-input {
  visibility: hidden;
  display: none;
}
</style>

<style lang="scss" scoped>
// #pac-input {
//   visibility: hidden;
//   display: none;
// }

.disable-map {
  pointer-events: none;
}

.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
