<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-card-text>
      <v-form ref="form">
        <v-row class="justify-center">
          <v-col
            cols="12"
            md="4"
          >
            <v-btn
              v-if="option===1 || option===3"
              class="mb-2"
              color="primary"
              block
              @click="pickFile"
            >
              Add Image
              <v-icon>{{ icons.mdiPlus }}</v-icon>
            </v-btn>

            <div
              style="display: none;"
            >
              <label
                for="s"
                htmlFor="s"
              >...</label>
              <input
                id="s"
                ref="imageRef"
                name="s"
                type="file"
                accept="image/jpeg"
                @change="onFileChangeAddImage($event)"
              />
            </div>

            <v-card elevation="8">
              <v-carousel
                v-if="collectionImages.length"
                cycle
                hide-delimiter-background
                height="auto"
                width="350"
              >
                <v-carousel-item
                  v-for="(img, i) in collectionImages"
                  :key="i"
                >
                  <v-btn
                    v-if="option === 1 || option === 3"
                    block
                    color="secondary"
                    style="z-index: 10;"
                    @click="onDeleteImg(img)"
                  >
                    <v-icon>
                      {{ icons.mdiDelete }}
                    </v-icon>
                  </v-btn>
                  <v-img
                    :src="img.url"
                    contain
                    width="100%"
                  />
                </v-carousel-item>
              </v-carousel>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-textarea
              v-model="dataComputed.description"
              rows="5"
              :label="`${t('sports.description')}`"
              dense
              outlined
              :readonly="option===2"
            />
          </v-col>

          <!-- action buttons -->
          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :info-required="false"
            :no-cancel="option === 3"
            :no-arrow="option === 2"
            :no-save="true"
            @onCancel="onCancelButton"
            @onNextTab="changeTab"
          >
          </options-buttom-tab>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable no-restricted-syntax */
/* eslint-disable arrow-body-style */
import { mdiPlus, mdiDelete } from '@mdi/js'
import { ref, computed, onMounted } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'
import { editFacilityImage, deleteFacilityImage } from '@api'
import { success } from '@core/utils/toasted'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()

    const form = ref(null)
    const optionComputed = computed(() => props.option)
    const dataComputed = computed(() => props.dataParams)
    const imageRef = ref(null)
    const collectionImages = ref([])

    const updateCollectionImages = (fullImages, relImages) => {
      collectionImages.value = fullImages.map((e, i) => {
        return {
          data: null,
          url: e,
          path: relImages[i],
        }
      })
    }

    const updateImage = async image => {
      const formData = new FormData()
      if (image && dataComputed.value.id) {
        formData.append('file', image)
        const responseLogo = await editFacilityImage(formData, dataComputed.value.id)

        if (responseLogo.ok) {
          success(responseLogo.message)
          updateCollectionImages(responseLogo.data.full_url_images, responseLogo.data.rel_path_images)
        }
      }
    }

    const deleteImage = async path => {
      const formData = new FormData()
      if (path && dataComputed.value.id) {
        formData.append('file', path)
        const responseLogo = await deleteFacilityImage({ image_path: path }, dataComputed.value.id)

        if (responseLogo.ok) {
          success(responseLogo.message)
          updateCollectionImages(responseLogo.data.full_url_images, responseLogo.data.rel_path_images)
        }
      }
    }

    const pickFile = () => {
      imageRef.value.click()
    }

    const addDataImage = async i => {
      collectionImages.value.push({
        data: i,
        url: URL.createObjectURL(i),
        path: null,
      })

      if (optionComputed.value === 3) await updateImage(i)
    }

    const onFileChangeAddImage = event => {
      const arrayImgs = event.target.files
      if (!arrayImgs.length) {
        return
      }

      for (const i of arrayImgs) {
        const img = new Image()
        img.src = URL.createObjectURL(i)
        img.onload = () => {
          addDataImage(i)
        }
      }
    }

    const onDeleteImg = async img => {
      let collImages = collectionImages.value
      if (optionComputed.value === 3) {
        const finded = collImages.find(i => i.url === img.url)
        if (finded) await deleteImage(finded.path)
      }
      collImages = collImages.filter(i => i.url !== img.url)
      collectionImages.value = collImages
    }

    const onCancelButton = () => {
      form.value.reset()
      collectionImages.value = []
      emit('cancelAction')
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        if (collectionImages.value.length && optionComputed.value === 1) dataComputed.value.imagesDescription = collectionImages.value.filter(e => e.data)
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    onMounted(async () => {
      if (dataComputed.value.full_url_images && dataComputed.value.full_url_images.length) updateCollectionImages(dataComputed.value.full_url_images, dataComputed.value.real_path_images)
    })

    return {
      form,
      imageRef,
      collectionImages,

      // computed
      dataComputed,
      optionComputed,

      // Methods
      onCancelButton,
      changeTab,
      pickFile,
      addDataImage,
      onFileChangeAddImage,
      onDeleteImg,
      updateImage,
      deleteImage,
      updateCollectionImages,

      // i18n
      t,

      icons: {
        mdiPlus,
        mdiDelete,
      },
    }
  },
}
</script>

<style >
#pac-input {
  visibility: hidden;
  display: none;
}
</style>

<style lang="scss" scoped>
// #pac-input {
//   visibility: hidden;
//   display: none;
// }

.disable-map {
  pointer-events: none;
}

.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
