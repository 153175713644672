<template>
  <v-card
    id="facility-tab-fanaty"
    flat
    class="mt-5"
  >
    <v-card-text>
      <v-form ref="form">
        <v-row
          justify="start"
        >
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model.number="dataComputed.fanaty_service_fee_percent"
              :label="t('groups.fanaty_service_fee_percent')"
              dense
              outlined
              prefix="%"
              :readonly="option===2"
              :rules="[required, between(dataComputed.fanaty_service_fee_percent, 0, 100), regexValidator(dataComputed.fanaty_service_fee_percent, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format')]"
              @input="e => {
                if (!charUnique(e, '.') || regexValidator(dataComputed.fanaty_service_fee_percent, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format') !== true) {
                  dataComputed.fanaty_service_fee_percent = null
                }
              }"
              @keypress="onlyNumberDot"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model.number="dataComputed.fanaty_service_fee"
              :label="t('groups.fanaty_service_fee')"
              dense
              outlined
              prefix="$"
              :readonly="option===2"
              :rules="[required, regexValidator(dataComputed.fanaty_service_fee, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format')]"
              @input="e => {
                if (!charUnique(e, '.') || regexValidator(dataComputed.fanaty_service_fee, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format') !== true) {
                  dataComputed.fanaty_service_fee = null
                }
              }"
              @keypress="onlyNumberDot"
            ></v-text-field>
          </v-col>

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :info-required="true"
            :no-cancel="option === 3"
            :no-arrow="true"
            @onCancel="onCancelAction"
            @onSave="onSaveAction"
          />
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  ref, computed,
} from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import {
  required, between, numberValidator, regexValidator, charUnique,
} from '@core/utils/validation'
import { onlyNumberDot } from '@core/utils/functionsHelpers'

import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  name: 'FacilityTabFanaty',
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()

    const form = ref(null)
    const isOpenShareLink = ref(false)
    const connectStatus = ref(null)
    const onBoarddingLink = ref(null)

    const optionComputed = computed(() => props.option)
    const dataComputed = computed(() => props.dataParams)

    const onSaveAction = () => {
      emit('save', dataComputed.value)
    }

    const onCancelAction = () => {
      form.value.reset()
      emit('cancelAction')
    }

    return {
      form,
      isOpenShareLink,
      connectStatus,
      onBoarddingLink,

      // computed
      dataComputed,
      optionComputed,

      // Methods
      onSaveAction,
      onCancelAction,

      required,
      between,
      regexValidator,
      numberValidator,
      charUnique,
      onlyNumberDot,

      // i18n
      t,
    }
  },
}
</script>
