<template>
  <v-dialog
    id="share-link"
    v-model="isOpen"
    width="28rem"
    overlay-color="black"
    overlay-opacity="0.8"
    @click:outside="$emit('update:is-open', false)"
  >
    <v-card
      class="py-5"
      color="dialog"
    >
      <v-icon
        class="absolute-close"
        color="close"
        @click="$emit('update:is-open', false)"
      >
        {{ icons.mdiClose }}
      </v-icon>

      <v-card-text class="pt-4 pb-0">
        <v-card-title class="justify-center">
          {{ title || t('tooltip.share') }}
        </v-card-title>
        <v-card-subtitle
          v-if="subtitle"
          class="d-flex justify-center"
        >
          {{ subtitle }}
        </v-card-subtitle>
      </v-card-text>

      <v-card-text>
        <v-row
          class="
          my-2
          mx-3
          px-0
          py-1
          ma-0"
        >
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center"
          >
            <v-text-field
              id="refCopy"
              ref="refVCopy"
              v-model="link"
              :label="label || t('fields.link')"
              style="width: 100%;"
              dense
              outlined
              readonly
              hide-details
            >
              <template v-slot:append>
                <v-fade-transition
                  v-if="link"
                  leave-absolute
                >
                  <v-icon
                    :size="iconSize"
                    :color="!isCopied ? 'close' : 'success'"
                    @click="copyAction"
                  >
                    {{ !isCopied ? icons.mdiContentCopy : icons.mdiCheck }}
                  </v-icon>
                </v-fade-transition>
              </template>
            </v-text-field>

            <v-tooltip
              v-if="showOpenTab && link"
              bottom
            >
              <template #activator="{ on: tooltip, attrs }">
                <v-icon
                  v-if="link"
                  :size="iconSize"
                  color="close"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="{ ...tooltip }"
                  @click="openNewTab(link)"
                >
                  {{ icons.mdiTabPlus }}
                </v-icon>
              </template>
              <span>{{ t('tooltip.open_tab') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable vuejs-accessibility/alt-text */
import {
  ref, computed, getCurrentInstance,
} from '@vue/composition-api'
import {
  mdiClose, mdiContentCopy, mdiCheck, mdiTabPlus,
} from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import useCryptoJs from '@core/utils/useCryptoJs'
import { success } from '@core/utils/toasted'
import { useClipboard } from '@vueuse/core'
import { isMobile, openNewTab } from '@core/utils'

export default {
  name: 'ShareLink',
  model: {
    prop: 'isOpen',
    event: 'update:is-open',
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      require: false,
      default: 'Share',
    },
    subtitle: {
      type: String,
      require: false,
      default: '',
    },
    label: {
      type: String,
      require: false,
      default: '',
    },
    link: {
      type: String,
      require: true,
      default: '',
    },
    iconSize: {
      type: Number,
      default: 24,
    },
    showOpenTab: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { userData } = useCryptoJs()
    const { copy, isSupported } = useClipboard()
    const vm = getCurrentInstance().proxy

    const refVCopy = ref(null)
    const refCopy = ref(null)
    const isCopied = ref(false)

    const computedIsMobile = computed(() => isMobile())
    const computedLink = computed(() => props.link)

    const computedIsOpen = computed({
      set: val => emit('update:is-open', val),
      get: () => props.isOpen,
    })

    const successCopy = () => {
      setTimeout(() => {
        success(vm.$t('tooltip.copied'))
        isCopied.value = true

        setTimeout(() => {
          isCopied.value = false
          refVCopy.value.onBlur()
        }, 2000)
      }, 500)
    }

    const copyToClipboard = () => {
      const el = document.querySelector('#refCopy')

      // handle iOS as a special case
      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        // save current contentEditable/readOnly status
        const editable = el.contentEditable
        const { readOnly } = el

        // convert to editable with readonly to stop iOS keyboard opening
        el.contentEditable = true
        el.readOnly = true

        // create a selectable range
        const range = document.createRange()
        range.selectNodeContents(el)

        // select the range
        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
        el.setSelectionRange(0, 999999)

        // restore contentEditable/readOnly to original state
        el.contentEditable = editable
        el.readOnly = readOnly
      } else {
        el.select()
      }

      // execute copy command
      document.execCommand('copy')
      successCopy()
    }

    const copyAction = () => {
      if (!isSupported) {
        copyToClipboard()
      } else if (!isCopied.value) {
        copy(props.link).then(() => {
          successCopy()
        })
      }
    }

    return {
      // data
      userData,
      refCopy,
      refVCopy,
      isCopied,

      // computed
      computedIsOpen,
      computedIsMobile,
      computedLink,

      // methods
      copyAction,
      openNewTab,

      // i18n
      t,

      // icons
      icons: {
        mdiClose,
        mdiContentCopy,
        mdiCheck,
        mdiTabPlus,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
  .absolute-close {
    position: absolute;
    top: 10px;
    right: 10px;
    // background-color: #222222;
    // color: #8a8d93;
    border-radius: 50px !important;
    padding: 2px;
  }

  .phone-item {
    min-height: 25px;
    height: 25px;
  }

  .btn-not-hov {
    color: #ff3232 !important;
    caret-color: #ff3232 !important;
    cursor: pointer;
  }

  .no-bg-hover::before {
    background-color: transparent !important;
  }
</style>
